<template>
  <div class="admin-royalties">
    <portal to="page-call-to-action">
      <ebp-button
        v-text="$t('export-csv')"
        @click="exportCSV"
        :block="isSmallScreen"
        :loading="downloading"
      />
    </portal>
    <back-button>
      <ebp-card class="flex-fill mt-sm-0 mt-3 ml-sm-3 mb-0">
        <div class="d-flex flex-column align-content-between">
          <p class="mb-2 flex-fill mr-1 text-muted">
            {{ $t("payment-summary") }}
          </p>
          <div class="d-flex flex-row">
            <p class="mb-0 flex-fill mr-1">
              # of Records:
              <b>{{ royalties.length }}</b>
            </p>
            <p class="mb-0 flex-fill mr-1">
              {{ $t("amount") }}:
              <b>{{ formatCurrency(outbound_payment.final_amount, "GBP") }}</b>
            </p>
            <p class="mb-0 flex-fill mr-1">
              {{ $t("date") }}:
              <b>{{ formatDate(outbound_payment.created_at) }}</b>
            </p>
            <p class="mb-0 flex-fill mr-1">
              {{ $t("payment-method") }}:
              <b>{{
                outbound_payment.payment_method
                  ? outbound_payment.payment_method.name
                  : $t("n-a")
              }}</b>
            </p>
          </div>
        </div>
      </ebp-card>
    </back-button>

    <data-table
      :items="royalties"
      :headers="headers"
      ref="table"
      hide-actions
      :limit="50000"
      :paginationTop="false"
      :paginationBottom="false"
      :globalLoading="loading"
    >
      <template #cell(amount)="{ item }">
        {{
          formatCurrency(
            item.base_currency_final_amount || item.base_currency_amount,
            item.base_currency.code
          )
        }}
      </template>

      <template #cell(commission)="{ item }">
        {{
          formatCurrency(
            ((item.base_currency_final_amount || item.base_currency_amount) /
              100) *
              item.commission,
            item.base_currency.code
          )
        }}
      </template>

      <template #cell(status)="{ item }">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #cell(client)="{ item }">
        {{ item.user.name }}
      </template>

      <template #cell(sales_channel)="{ item }">
        {{ item.sales_channel.name }}
      </template>

      <template #cell(book)="{ item }">
        <router-link
          target="_blank"
          :to="{
            name: 'AdminEditBook',
            params: { id: item.book.id }
          }"
        >
          {{ item.book.title }}
        </router-link>
      </template>

      <template #cell(date_of_sale)="{ item }">
        {{ formatDate(item.sale_record.date_of_sale) }}
      </template>

      <template #cell(territory)="{ item }">
        <span
          v-if="item.sale_record.territory"
          v-tooltip="item.sale_record.territory.name"
        >
          {{ item.sale_record.territory.code }}
        </span>
      </template>

      <template #cell(net_sales)="{ item }">
        {{ formatNumber(item.sale_record.net_sales) }}
      </template>

      <template #cell(units_returned)="{ item }">
        {{ formatNumber(item.sale_record.units_returned) }}
      </template>

      <template #cell(units_sold)="{ item }">
        {{ formatNumber(item.sale_record.units_sold) }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          :loading="removing === item.id"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-form-select
            :options="addAllOption(royaltyStatuses)"
            v-model="filters.status"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>

        <!-- Book filter -->
        <adt-filter :label="$t('book')">
          <item-search
            action="books/search"
            label="title"
            trackBy="id"
            v-model="filters.book_id"
          />
        </adt-filter>

        <!-- Sales channel filter -->
        <adt-filter :label="$t('sales-channel')">
          <item-search
            action="salesChannels/search"
            label="name"
            trackBy="id"
            v-model="filters.sales_channel_id"
          />
        </adt-filter>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "@/components/data-table";
import AdtFilter from "@/components/core/adt-filter";
import royaltySchema from "@/validation-schemas/sale-import";
import ItemSearch from "@/components/core/item-search";
import royaltyStatuses from "@/fixtures/royalty-statuses";
import EbpCard from "@/components/core/ebp-card";

import truncate from "@/helpers/truncate";
import { mapState } from "vuex";
import { find } from "lodash-es";
import FileDownload from "js-file-download";

export default {
  name: "admin-royalties",
  components: {
    DataTable,
    ItemSearch,
    AdtFilter,
    EbpCard
  },
  data() {
    return {
      royaltyStatuses: royaltyStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      headers: [
        { key: "amount", sortable: true },
        "status",
        "book",
        { key: "date_of_sale", sortable: true },
        "territory",
        "sales_channel",
        "units_sold",
        "units_returned",
        "net_sales"
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleImport: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        user_id: null,
        book_id: null,
        sales_channel_id: null,
        status: null,
        inbound_payment_id: null,
        outbound_payment_id: null
      },
      processing: false,
      outbound_payment: {},
      royalties: [],
      loading: false,
      downloading: false
    };
  },
  mounted() {
    const channel = this.$pusher.subscribe("import-processed");
    channel.bind("import-processed", ({ royalty }) => {
      this.$router.push({
        name: "ViewSaleRecords",
        query: {
          sales_import_id: royalty.id
        }
      });
    });

    this.$watch(
      () => {
        return this.$refs.table.tableData;
      },
      items => {
        this.processing = !!items.filter(i => i.status === "processing").length;
      }
    );

    this.filters.outbound_payment_id = this.$route.query.outbound_payment_id;
    if (!this.filters.outbound_payment_id)
      return this.$router.push({ name: "PaymentHistory" });
    this.get();
  },
  destroyed() {
    this.$pusher.unsubscribe("import-processed");
  },
  computed: {
    ...mapState("royalties", ["processorTypes"])
  },
  methods: {
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("royalties/get", {
          ...this.filters,
          limit: 50000
        });
        this.royalties = res.data.royalties.data;
        this.outbound_payment = res.data.outbound_payment;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async exportCSV() {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch("reports/exportCSV", {
          outbound_payment_id: this.outbound_payment.id
        });

        FileDownload(res, "sales-by-royalties.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    getProcessorName(code) {
      const type = find(this.processorTypes, o => o.code === code);
      return type ? type.name : "";
    },
    truncate,
    added() {
      this.showAddSaleImport = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(royalty) {
      if (
        confirm(
          this.$t("remove-message", {
            name: this.formatCurrency(
              royalty.amount,
              royalty.sale_record.currency.code
            )
          })
        )
      ) {
        this.removing = royalty.id;
        try {
          await this.$store.dispatch("royalties/delete", royalty.id);
          this.$refs.table.change("id", royalty.id);
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(royalty) {
      this.editedItem = royalty;
      this.open = true;
    },
    async save() {
      this.error = null;
      const royalty = this.editedItem;
      const validation = royaltySchema.validate(royalty);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("royalties/update", royalty);
          this.$refs.table.change("id", royalty.id, royalty);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
