<template>
  <div class="item-search">
    <div class="d-flex">
      <b-form-input
        type="search"
        placeholder="Search..."
        class="form-control"
        v-model="q"
        @keypress.enter="search"
      ></b-form-input>
      <ebp-button @click="search" class="ml-1">
        <i class="bx bx-search"></i>
      </ebp-button>
    </div>

    <div class="results" v-if="showDropdown">
      <spinner v-if="loading" />
      <template v-else>
        <div
          class="result"
          v-for="(item, i) in items"
          :key="i"
          @click="select(item)"
        >
          {{ customLabel ? customLabel(item) : item[label] }}
        </div>
        <p class="text-center my-2" v-if="!items.length">
          {{ $t("no-items") }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "item-search",
  props: {
    action: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    label: String,
    trackBy: String,
    customLabel: Function
  },
  data() {
    return {
      items: [],
      q: "",
      loading: false,
      listener: null,
      show: false
    };
  },
  mounted() {
    this.listener = document.addEventListener("click", () => {
      this.items = [];
      this.show = false;
    });
  },
  destroyed() {
    removeEventListener("click", this.listener);
  },
  computed: {
    showDropdown() {
      return this.loading || this.show;
    }
  },
  methods: {
    select(item) {
      this.q = item[this.label];
      this.$emit("input", item[this.trackBy]);
    },
    async search() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch(this.action, {
          search: this.q,
          ...this.$props.filters
        });
        this.items = res.data.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
      this.show = true;
    }
  },
  watch: {
    q(q) {
      if (!q) {
        this.items = [];
        this.$emit("input", null);
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss">
.item-search {
  position: relative;

  .results {
    position: absolute;
    width: 100%;
    margin-bottom: -100%;
    background: white;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 3.2px;
    margin-top: 5px;
    z-index: 999;
    max-height: 400px;
    overflow-y: auto;

    .ebp-spinner {
      font-size: 0.8em !important;
      padding: 10px;
    }

    .result {
      width: 100%;
      cursor: pointer;
      font-size: 0.92em;
      margin: 0;
      background: rgb(250, 250, 250);
      border-bottom: 1px solid rgb(218, 216, 216);
      padding: 5px 10px;
      text-align: left;

      &:hover {
        background: $primary;
        color: white;
      }
    }
  }
}
</style>
