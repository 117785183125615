import Joi from "joi";
import errorMessages from "./errorMessages.json";

export default Joi.object({
  filename: Joi.string()
    .required()
    .messages({
      "string.base": "validation-errors.upload-file"
    }),
  sales_channel_id: Joi.number().required(),
  processor_type: Joi.string().required(),
  total_records: Joi.string().optional(),
  processed: Joi.string().optional,
  imported_at: Joi.string().optional(),
  date_of_sale: Joi.string().optional(),
  notes: Joi.string()
    .max(2000)
    .allow(null, "")
})
  .unknown()
  .messages(errorMessages);
